






































import StakingViewPrograms from './StakingViewPrograms.vue';
import StakingViewInfoTiles from './StakingViewInfoTiles.vue';
import { mapActions, mapState } from 'vuex';
import { AxiosError } from 'axios';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { enableOperatorFeatureFlag } from '@/api/FeatureFlags';
import type { PropType } from 'vue';

export default {
  name: 'StakingView',
  props: {
    modal: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    }
  },
  components: {
    StakingViewPrograms,
    StakingViewInfoTiles
  },
  computed: {
    ...mapState('FeatureFlags', ['features', 'loading']),
    h2eFlag(): boolean {
      return this.features.find((v) => v.name === 'hold_to_earn') ?? {};
    },
    disableStaking(): boolean {
      return !this.h2eFlag.name || !this.$role.can.update('h2e');
    }
  },
  methods: {
    ...mapActions('FeatureFlags', ['getOperatorFeatureFlags']),
    changeHandler(switchStatus: boolean): void {
      if (!this.h2eFlag.name) {
        return;
      }

      enableOperatorFeatureFlag(this.h2eFlag.featureFlagId, switchStatus)
        .then((): void => {
          this.$toast.success(
            `Hold to Earn has been turned ${switchStatus ? 'on' : 'off'}`
          );

          this.getOperatorFeatureFlags();
        })
        .catch((err: AxiosError): void => {
          errorToastMessage(err);
        });
    }
  }
};
