













































import { mapActions, mapState, mapGetters } from 'vuex';
import InfoTile from '@/components/info-tile/InfoTile.vue';
import { roundNumber } from '@/helpers/roundNumber';
import { usdToOperatorCurrency } from '@/helpers/currencyHelper';
import type { ClientsInfoState } from '@/store/modules/clients-info/ClientsInfoState';

export default {
  name: 'StakingViewInfoTiles',
  data(): any {
    return {
      polling: null
    };
  },
  components: {
    InfoTile
  },
  computed: {
    ...mapState('Auth', ['tokenSymbol']),
    ...mapState('TokensInfo', ['frozenTokens', 'tokenPrice']),
    ...mapState('SuperAdmin', ['operator']),
    ...mapState('ClientsInfo', {
      loadingInfo: (state: ClientsInfoState) => state.loadingInfo
    }),
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('StakingPrograms', [
      'totalInStakingUsers',
      'totalUsersLastWeekRatio'
    ]),
    frozenGraphType(): string {
      return this.frozenTokens.fromLastWeek >= 0 ? 'orange' : 'red';
    },
    totalAtCurrency(): number {
      return usdToOperatorCurrency(this.frozenTokens.amountUsd);
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.loadData();
        }
      },
      immediate: true
    },
    operator: {
      handler(): void {
        this.loadData();
      },
      deep: true
    }
  },
  created(): void {
    this.polling = setInterval(() => {
      this.loadData();
    }, 20000);
  },
  methods: {
    ...mapActions('TokensInfo', ['getFrozenTokensData', 'getTokenPriceData']),
    ...mapActions('ClientsInfo', ['getClientsInfo']),
    formatNumber(value: number): string {
      if (!+value) {
        return '0';
      }

      return roundNumber(value, 7);
    },
    loadData(): void {
      this.getFrozenTokensData();
      this.getTokenPriceData();
      this.getClientsInfo();
    }
  },
  beforeDestroy(): void {
    clearInterval(this.polling);
  }
};
