


























import type { PropType } from 'vue';
import { roundNumber } from '@/helpers/roundNumber';
import { mapGetters } from 'vuex';

export default {
  name: 'InfoTile',
  props: {
    graphType: {
      type: String as PropType<string>,
      default: (): string => null
    },
    progressTitle: {
      type: String as PropType<string>,
      default: (): string => 'Last week'
    },
    progress: {
      type: [Number, String] as PropType<number | string>,
      default: (): string => '0 %'
    },
    hideProgress: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    }
  },
  methods: {
    roundNumber
  },
  computed: {
    ...mapGetters('Onboarding', ['isMobile']),
    computedGraphType(): string {
      if (this.isMobile) return null;
      if (this.hideProgress) {
        return null;
      }

      if (this.graphType) {
        return this.graphType;
      }

      const progress = parseFloat(this.progress);
      return progress > 0 ? 'green' : progress < 0 ? 'red' : null;
    }
  }
};
